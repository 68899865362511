<template>
  <section v-if="namedDetails" class="page-content">
    <p class="description">
      W zakładce <strong>Prelekcja</strong> uzupełnij dane w formularzu.
      Informacje będą wyświetlały się w&nbsp;agendzie na stronie wydarzenia.
    </p>
    <download-data page-type="_lt_" v-if="!disabled" />
    <h2>Prelekcje na wydarzeniu</h2>
    <v-input
      id="_lt_title"
      name="_lt_title"
      label="Tytuł prelekcji"
      :type="'text'"
      :required="true"
      :disabled="disabled"
      @auto-save="sendData(false)"
      v-model="formData._lt_title"
    />
    <v-textarea
      id="_lt_desc"
      name="_lt_desc"
      label="Opis prelekcji"
      :defaultValue="this.namedDetails[this.activeEdition + '_lt_desc']"
      :disabled="disabled"
      @auto-save="sendData(false)"
      :max-characters="700"
      v-model="formData._lt_desc"
    />
    <div v-for="(prelegent, index) in formData._lt_prelegents" :key="index">
      <div class="double-input-container">
        <v-input
          :id="`_lt_prelegent_${prelegent}_fname`"
          :name="`_lt_prelegent_${prelegent}_fname`"
          label="Imię Prelegenta"
          :required="true"
          :disabled="disabled"
          @auto-save="sendData(false)"
          v-model="formData[`_lt_prelegent_${prelegent + 0}_fname`]"
        />
        <v-input
          :id="`_lt_prelegent_${prelegent}_sname`"
          :name="`_lt_prelegent_${prelegent}_sname`"
          label="Nazwisko Prelegenta"
          :required="true"
          :disabled="disabled"
          @auto-save="sendData(false)"
          v-model="formData[`_lt_prelegent_${prelegent + 0}_sname`]"
        />
      </div>
      <v-textarea
        :id="`_lt_prelegent_${prelegent}_bio`"
        :name="`_lt_prelegent_${prelegent}_bio`"
        :label="'Bio Prelegenta'"
        :required="true"
        :disabled="disabled"
        @auto-save="sendData(false)"
        :max-characters="700"
        v-model="formData[`_lt_prelegent_${prelegent + 0}_bio`]"
      />
      <h2>Zdjęcie prelegenta</h2>
      <div class="description">
        <p class="margin-bt-3">
          W celu zoptymalizowania dodawanej grafiki, zalecamy skorzystanie z
          intuicyjnego narzędzia dostępnego pod adresem
          <strong>
            <a
              href="https://www.easy-resize.com/pl/"
              target="_blank"
              rel="nofollow noreferrer"
            >
              www.easy-resize.com
            </a>
          </strong>
        </p>
      </div>
      <v-upload
        :id="`_lt_prelegent_${prelegent}_photo`"
        :name="`_lt_prelegent_${prelegent}_photo`"
        label="Wybierz zdjęcie"
        label-alt="Zmień zdjęcie"
        :confirmMessage="'Potwierdź dodanie zdjęcia'"
        file-type="raster"
        :maxSize="3000000"
        :edition="activeEdition"
        :company="companyData.id"
        :disabled="disabled"
        v-model="formData[`_lt_prelegent_${prelegent + 0}_photo`]"
      />
      <main-btn
        :content="'Usuń prelegenta'"
        :disabled="disabled"
        :gray="true"
        @click="removePrelegent(prelegent)"
      />
    </div>
    <p class="margin-tp-5">
      Maksymalna liczba prelegentow: <span class="blue-text">3</span>
    </p>
    <main-btn
      v-if="formData._lt_prelegents.length < 3"
      @click="addPrelegent"
      class="margin-bt-2"
      :disabled="disabled"
      content="Dodaj prelegenta"
      :gray="true"
    />
    <div v-if="!disabled" class="send-btn-cont">
      <main-btn
        :content="'Zapisz wersję roboczą'"
        :tooltip="'Wersja robocza umożliwia Ci naniesienie zmian i edytowanie tej zakładki. Pamiętaj aby jak najszybciej zapisać wersję ostateczną materiałów, która zostanie przesłana do organizatora wydarzenia.'"
        :submit="true"
        :saving="loading"
        :gray="true"
        @click="sendData(false)"
      />
      <main-btn
        :content="'Zapisz wersję ostateczną'"
        :tooltip="'Wersja ostateczna to finalna informacja jaka zostanie przesłana do Pracuj.pl na potrzeby organizacji wydarzenia'"
        :submit="true"
        :saving="loading"
        @click="sendData(true, '_lt_disabled')"
      />
    </div>
    <div v-else>
      <p v-if="this.checkIfServiceIsBought('Prelekcja')">
        <strong
          >Ustawienia zakładki zostały przekazane do Pracuj.pl. Jeśli chcesz
          dokonać zmian skontaktuj się pod adresem
          <a class="important-info" :href="`mailto:${companyGuardian.email}`">{{
            companyGuardian.email
          }}</a></strong
        >
      </p>
      <p v-else>
        <strong>Ta usługa nie została wykupiona przez Twoją firmę</strong>
      </p>
    </div>
    <p class="text-center margin-tp-1" v-show="message">
      <strong :class="`message--${messageType}`">{{ message }}</strong>
    </p>
  </section>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import sendDataAndDispatch from "@/mixins/sendDataAndDispatch.js";
import assignDataFromDatabase from "@/mixins/assignDataFromDatabase.js";
import checkIfServiceIsBought from "@/mixins/checkIfServiceIsBought.js";

export default Vue.extend({
  mixins: [sendDataAndDispatch, assignDataFromDatabase, checkIfServiceIsBought],
  data() {
    return {
      loading: false,
      message: "",
      messageType: "ok",
      disabled: false,
      formData: {
        _lt_title: "",
        _lt_desc: "",
        _lt_prelegents: [],
        _lt_disabled: false,
      },
    };
  },
  methods: {
    addPrelegent() {
      let newPrelegentId;

      if (this.formData._lt_prelegents.length === 0) {
        newPrelegentId = 1;
      } else {
        newPrelegentId = Math.max(...this.formData._lt_prelegents) + 1;
      }

      if (this.formData._lt_prelegents.length < 3) {
        this.formData._lt_prelegents.push(newPrelegentId);

        this.formData[`_lt_prelegent_${newPrelegentId}_fname`] = "";
        this.formData[`_lt_prelegent_${newPrelegentId}_sname`] = "";
        this.formData[`_lt_prelegent_${newPrelegentId}_bio`] = "";
        this.formData[`_lt_prelegent_${newPrelegentId}_photo`] = "";
      }

      this.sendData(false);
    },
    removePrelegent(prelegent) {
      this.formData._lt_prelegents = this.formData._lt_prelegents.filter(
        (e) => e !== prelegent
      );
      this.formData[`_lt_prelegent_${prelegent}_fname`] = undefined;
      this.formData[`_lt_prelegent_${prelegent}_sname`] = undefined;
      this.formData[`_lt_prelegent_${prelegent}_bio`] = undefined;
      this.formData[`_lt_prelegent_${prelegent}_photo`] = undefined;

      this.sendData(false);
    },
    checkIfPageIsDisabled() {
      this.disabled =
        !this.checkIfServiceIsBought("Prelekcja") || this.formData._lt_disabled;

      if (!this.activeEditionOpened) {
        this.disabled = true;
      }
    },
    prepareData() {
      this.assingData("_lt_title", "");
      this.assingData("_lt_desc", "");
      this.assingData("_lt_prelegents", [], "array", true);
      this.assingData("_lt_disabled", false, "boolean");

      this.formData._lt_prelegents.forEach((prelegentId, index) => {
        this.assingData(`_lt_prelegent_${prelegentId}_fname`, "");
        this.assingData(`_lt_prelegent_${prelegentId}_sname`, "");
        this.assingData(`_lt_prelegent_${prelegentId}_bio`, "");
        this.assingData(`_lt_prelegent_${prelegentId}_photo`, "");
      });
    },
  },
  mounted() {
    this.prepareData();
    this.checkIfPageIsDisabled();
  },
  computed: {
    ...mapGetters(["companyData", "companyGuardian", "activeEditionOpened"]),
  },

  watch: {
    companyData: function () {
      this.prepareData();
      this.checkIfPageIsDisabled();
    },
    activeEditionOpened() {
      this.checkIfPageIsDisabled();
    },
  },
});
</script>
