import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'companyServices',
    ]),
  },
  methods: {
    checkIfServiceIsBought: function(serviceName) {
      return this.companyServices.some((e) => e.name === serviceName);
    },
  }
}